#video-bg {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

#login-root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login-box {
  position: relative;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  align-items: center;
  width: 30vw;
  background-color: rgb(255, 255, 255);
  margin: auto;
  box-shadow: 0 7px 18px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

#login-content {
  padding: 12% 15%;
}

#login-logo {
  width: 90%;
  margin-bottom: 5px;
}

#login-box h1 {
  color: rgb(45 61 104);
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: bold;
}

#login-box hr {
  border-top: 1px solid rgba(5, 4, 4, 0.089);
}

@media only screen and (max-width: 700px) {
  #login-box {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    transform: scale(1);
  }
}
