@font-face {
  font-family: "header";
  src: local("BizmoMedium"),
  url("./fonts/BizmoMedium.ttf") format("truetype")
}

@font-face {
  font-family: "subheader";
  src: local("TTNormsProMedium"),
  url("./fonts/TTNormsProMedium.ttf") format("truetype")
}

@font-face {
  font-family: "content";
  src: local("TTNormsProRegular"),
  url("./fonts/TTNormsProRegular.ttf") format("truetype")
}

.logo img {
  left: 0;
  top: 0;
  bottom: 25%;
  right: 0;
  margin: auto;
  position: absolute;
  width: 108px;
  opacity: 0.4;
}

.linkBox {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  min-width: fit-content;
  overflow: hidden;
  width: 35%;
  cursor: auto;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(0 0 0 / 8%) 0px 1px 3px 0px;
}

.calendar-box {
  display: flex !important;
  color: #3aaa35 !important;
  border-right: 1px solid rgba(0, 0, 0, 0.13) !important;
  padding: 10px !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.copy-button {
  padding: 10px !important;
  color: #3aaa35 !important;
  background-color: #ffffff !important;
  border-left: 1px solid rgba(0, 0, 0, 0.13) !important;
  cursor: pointer !important;
}

.App {
  display: flex;
}


@-moz-document url-prefix() {
  @page {
    size: A3 portrait !important;
  }
}

@page {
  size: A3 portrait !important;
  margin: 0mm;
}

@media print {
  * {
    max-width: 1200px !important;
    transition: none !important;
    -webkit-print-color-adjust: exact !important;
  }

  #gauge-chart1 svg,
  #gauge-chart2 svg,
  #gauge-chart3 svg,
  #gauge-chart4 svg,
  #gauge-chart5 svg,
  #gauge-chart6 svg {
    transform: scale(0.5) !important;
  }

  svg,
  .apexcharts-canvas,
  canvas,
  .graph-frame,
  div[type],
  span {
    max-width: 100% !important;
  }

  .MuiTabs-root {
    display: none !important;
  }

  .col-lg-12,
  .col-md-12,
  .col-sm-12,
  .col-xl-12,
  .MuiGrid-grid-xs-12,
  .MuiGrid-grid-md-12,
  .MuiGrid-grid-sm-12 {
    max-width: 1200px !important;
  }

  .col-lg-11,
  .col-md-11,
  .col-sm-11,
  .col-xl-11,
  .MuiGrid-grid-xs-11,
  .MuiGrid-grid-md-11,
  .MuiGrid-grid-sm-11 {
    max-width: 1100px !important;
  }

  .col-lg-10,
  .col-md-10,
  .col-sm-10,
  .col-xl-10,
  .MuiGrid-grid-xs-10,
  .MuiGrid-grid-md-10,
  .MuiGrid-grid-sm-10 {
    max-width: 1000px !important;
  }

  .col-lg-9,
  .col-md-9,
  .col-sm-9,
  .col-xl-9,
  .MuiGrid-grid-xs-9,
  .MuiGrid-grid-md-9,
  .MuiGrid-grid-sm-9 {
    max-width: 900px !important;
  }

  .col-lg-8,
  .col-md-8,
  .col-sm-8,
  .col-xl-8,
  .MuiGrid-grid-xs-8,
  .MuiGrid-grid-md-8,
  .MuiGrid-grid-sm-8 {
    max-width: 800px !important;
  }

  .col-lg-7,
  .col-md-7,
  .col-sm-7,
  .col-xl-7,
  .MuiGrid-grid-xs-7,
  .MuiGrid-grid-md-7,
  .MuiGrid-grid-sm-7 {
    max-width: 700px !important;
  }

  .col-lg-6,
  .col-md-6,
  .col-sm-6,
  .col-xl-6,
  .MuiGrid-grid-xs-6,
  .MuiGrid-grid-md-6,
  .MuiGrid-grid-sm-6 {
    max-width: 600px !important;
  }

  .col-lg-5,
  .col-md-5,
  .col-sm-5,
  .col-xl-5,
  .MuiGrid-grid-xs-5,
  .MuiGrid-grid-md-5,
  .MuiGrid-grid-sm-5 {
    max-width: 500px !important;
  }

  .col-lg-4,
  .col-md-4,
  .col-sm-4,
  .col-xl-4,
  .MuiGrid-grid-xs-4,
  .MuiGrid-grid-md-4,
  .MuiGrid-grid-sm-4 {
    max-width: 400px !important;
  }

  .col-lg-3,
  .col-md-3,
  .col-sm-3,
  .col-xl-3,
  .MuiGrid-grid-xs-3,
  .MuiGrid-grid-md-3,
  .MuiGrid-grid-sm-3 {
    max-width: 300px !important;
  }

  .col-lg-2,
  .col-md-2,
  .col-sm-2,
  .col-xl-2,
  .MuiGrid-grid-xs-2,
  .MuiGrid-grid-md-2,
  .MuiGrid-grid-sm-2 {
    max-width: 200px !important;
  }

  .col-lg-1,
  .col-md-1,
  .col-sm-1,
  .col-xl-1,
  .MuiGrid-grid-xs-1,
  .MuiGrid-grid-md-1,
  .MuiGrid-grid-sm-1 {
    max-width: 100px !important;
  }
}
